<template>
    <div>
      <el-form ref="bookForm" :model="book" :rules="rules" label-width="100px" class="book-form">
        <el-form-item label="社区" prop="community" required>
          <el-select v-model="book.community" placeholder="请输入社区">
            <el-option v-for="community in Tree" :key="community.deptId" :label="community.deptName" :value="community.deptName">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="预约时间" prop="bookTime">
          <el-date-picker v-model="book.bookTime" type="datetime"
           placeholder="请选择预约时间" value-format="yyyy-MM-dd hh:mm:ss" :picker-options="pickerOptions"></el-date-picker>
        </el-form-item>
        <el-form-item label="预约主题" prop="bookSubject">
          <el-input v-model="book.bookSubject" placeholder="请输入预约主题"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="book.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="book.address" placeholder="请输入地址"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitData">提交</el-button>
        </el-form-item>
      </el-form>
  
      <div class="book-list">
        <h3>已添加的预约</h3>
        <el-table :data="bookList" border style="width: 100%">
          <el-table-column prop="bookTime" label="预约时间"></el-table-column>
          <el-table-column prop="bookSubject" label="预约主题"></el-table-column>
          <el-table-column prop="community" label="预约社区"></el-table-column>
          
        </el-table>
      </div>
    </div>
  </template>

<script>
import { getTree, book,getBooks } from '@/api/user'

export default {
    data() {
        return {

            book: {
                community: '',
                uid: '',
                bookTime: '',
                bookSubject: '',
                phone: '',
                address: ''
            },
            rules: {
                community: [{ required: true, message: '请选择社区', trigger: 'change' }],
                bookTime: [{ required: true, message: '请选择预约时间', trigger: 'change' }],
                bookSubject: [{ required: true, message: '请输入预约主题', trigger: 'blur' }],
                phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
                address: [{ required: true, message: '请输入地址', trigger: 'blur' }]
            },

            Tree: [],
            bookList: [], // 存储已添加的预约信息
            pickerOptions: {
        disabledDate(time) {
          // 获取当前时间
          const now = new Date();
          // 获取未来三天的日期
          const threeDaysLater = new Date(now.getTime() + 3 * 24 * 60 * 60 * 1000);
          // 设置可选日期范围为当前日期到未来三天
          return time.getTime() < now.getTime() || time.getTime() > threeDaysLater.getTime();
        },
        disabledHours() {
          // 设置可选的小时范围为早上8点到下午6点
          return [0, 1, 2, 3, 4, 5, 6, 7, 18, 19, 20, 21, 22, 23];
        },
        disabledMinutes(hour) {
          // 如果是早上8点或者下午6点，则设置分钟范围为0到59；否则，全部禁用
          if (hour === 8 || hour === 18) {
            return [];
          } else {
            return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59];
          }
        }
    }

        };
    },
    created() {
        // 设置默认日期为明天早上8点
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(8, 0, 0, 0);
    const formattedDate = tomorrow.getFullYear() + '-' + 
    ('0' + (tomorrow.getMonth() + 1)).slice(-2) + '-' +
     ('0' + tomorrow.getDate()).slice(-2) + ' ' + 
     ('0' + tomorrow.getHours()).slice(-2) + ':' + 
     ('0' + tomorrow.getMinutes()).slice(-2) + ':' + 
     ('0' + tomorrow.getSeconds()).slice(-2);
    this.book.bookTime = formattedDate;
        getTree().then(res => {
            if (res.data) {
                // console.log(res.data);
                this.Tree = res.data;
                this.book.community = this.Tree[0].deptName;
            }
        }).catch(error => {
            console.error(error);
        });
        getBooks().then(res => {
            if (res.data) {
                console.log(res.data);
                this.bookList = res.data;
               
            }
        }).catch(error => {
            console.error(error);
        });
    },
    methods: {
        submitData() {
            this.$refs.bookForm.validate(valid => {
                if (valid) {
                    // 将表单数据发送到后端
                    book(this.book).then(res => {
                        this.$message.success('信息提交成功');
                    }).catch(error => {
                        console.error(error);
                        this.$message.error('信息提交失败');
                    });

                } else {
                    console.log('表单验证失败');
                    return false;
                }
            });
        }
    }
};
</script>

<style scoped>
.el-form-item__label {
    font-weight: bold;
}

.center-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
</style>